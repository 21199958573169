import React, { useEffect, useState } from "react";
import Quagga from "quagga";
import { useNavigate } from "react-router-dom";
import "../css/lector.css"
import { Button } from "react-bootstrap";

function LectorQr() {
  const navigate = useNavigate();
  useEffect(() => {
    Quagga.init(
      {
        inputStream: {
          name: "Live",
          type: "LiveStream",
          target: document.querySelector("#barcode-scanner"),
          constraints: {
            width: "30vw",
            height: "30vh",
            aspectRatio: { min: 1, max: 100 },
            facingMode: "environment", // or user
          },
        },
        decoder: {
          readers: ["code_39_reader"], // List of active readers
        },
      },
      function (err) {
        if (err) {
          console.log(err);
          return;
        }
        console.log("Initialization finished. Ready to start");
        Quagga.start();
      }
    );

    Quagga.onDetected((result) => {
      console.log(result.codeResult.code);
      return navigate("/bienAfecto/" + result.codeResult.code);
    });

    return () => {
      Quagga.stop();
    };
  }, []);

  return (
    <React.Fragment>
        <div style={{width:"100vw",height:"90vh",display:"flex",alignItems:"center",flexDirection:"column"}}>

        <label>Escanear Codigo de Barra</label>

        <div style={{width:"50vw",height:"50vh",display:"flex",justifyContent:"center"}} id='barcode-scanner'></div>

        <div>
            
        </div>
        <Button variant="primary" className="m-3" onClick={()=>{return navigate("/bienesAfectos")}}>Regresar</Button>
        </div>

     
    </React.Fragment>
  );
}

export default LectorQr;
