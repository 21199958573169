import React from "react";
import Nabvar from "./Nabvar";
import "../css/Menu.css";
import Dashboard from "./Dashboard";
import Cookies from "universal-cookie/es6";
import Login from "./Login";

function Menu() {
  const cookies = new Cookies();

  if (cookies.get("codigoUsuario") !== undefined) {
    return (
      <React.Fragment>
        <Nabvar />
        <Dashboard></Dashboard>
      </React.Fragment>
    );
  } else {
    return <Login></Login>;
  }
}

export default Menu;
