import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Menu from "./components/Menu";
import AgregarOrdenProgramada from "./components/AgregarOrdenProgramada";

import Firmar from "./components/Firmar";
import ConsultaOrden from "./components/ConsultaOrden";
import CrearCuenta from "./components/CrearCuenta";
import EquipoData from "./components/EquipoData";
import Sidebard from "./components/Sidebard";
import ConsultarOrdenFormato2 from "./components/ConsultarOrdenFormato2";
import BienesAfectos from "./components/BienesAfectos"
import Viewer from "./components/Viewer";
import LectorQr from "./components/LectorQr";
import ConsultaBienAfecto from "./components/ConsultaBienAfecto";



function App() {
  return (
    <BrowserRouter>
      <Routes>
        
          <Route path='/' element={<Login />} />
          <Route path='/dashboard' element={<Menu></Menu>} />
          <Route path='/ordenTrabajo' element={<EquipoData></EquipoData>} />
          <Route
            path='/agregarOrdenProgramada'
            element={<AgregarOrdenProgramada />}
          />
          <Route path='/firma' element={<Firmar />} />
          <Route path='/consulta/:folio' element={<ConsultaOrden />} />
          <Route path='/crearCuenta' element={<CrearCuenta />} />
          <Route path='/sidebard' element={<Sidebard />} />
          <Route path='/prueba' element={<ConsultarOrdenFormato2 />} />
          <Route path='/bienesAfectos' element={<BienesAfectos />} />
          <Route path='/bienAfecto/:noInventario' element={<ConsultaBienAfecto />} />
          
          <Route path='/bienesAfectosViewer' element={<Viewer />} />
          <Route path='/lector' element={<LectorQr />} />
       
      </Routes>
    </BrowserRouter>
  );
}

export default App;
