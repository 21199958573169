import React from "react";
import "../css/consultaFormato2.css";
import imagen from "../assets/logoOperadora.png";


function ConsultarOrdenFormato2({ ...props }) {
  return (
    <div id='content' >
      <main>
        <div className='contenedorImagenF2'>
          <img alt='Logo' src={imagen} className='imagenLogoF2' />
        </div>
        <div className='contenedorTextoEncabezado'>
          <div className='contenedorEncabezado1'>
            <label className='labelEncabezado'>
              <span className='spanEncabezado'>
                Desarolladora y Operadora de Infraestructura de Honduras
              </span>
            </label>
          </div>
          <div className='contenedorEncabezado2'>
            <label className='labelEncabezado'>
              <span className='spanEncabezado'>Título del Documento:</span>Orden
              de Trabajo
            </label>
            <label className='labelEncabezado2'>
              {" "}
              <span className='spanEncabezado'>Fecha:</span>01/07/2021
            </label>
          </div>

          <div className='contenedorEncabezado3'>
            <label className='labelEncabezado'>
              <span className='spanEncabezado'>Código: </span>Orden de Trabajo
            </label>
            <label className='labelEncabezado'>
              <span className='spanEncabezado'>Estatus:</span> Aprobado
            </label>
            <label className='labelEncabezado'>
              <span className='spanEncabezado'>REV. 1</span>
            </label>
          </div>

          <div className='contenedorEncabezado4'>
            <label className='labelEncabezado'>
              <span className='spanEncabezado'>
                Emitido por el Departamento:{" "}
              </span>
              MAO
            </label>
            <label className='labelEncabezado2'>
              {" "}
              <span className='spanEncabezado'>Página 1 de 1</span>
            </label>
          </div>
        </div>
      </main>

      <body>
        <div className='tituloBody'>
          <h1>Orden de Trabajo</h1>
        </div>

        <div className='datos'>
          <div className='datos1'>
            <label className='datoslabel1'>SERVICIO</label>
            <label className='datoslabel2'></label>
            <label className='datoslabel1'>FOLIO</label>
            <label className='datoslabel2'></label>
          </div>
          <div className='datos2'>
            <label className='datoslabel1'>EDIFICIO</label>
            <label className='datoslabel2'></label>
            <label className='datoslabel1'>NIVEL</label>
            <label className='datoslabel2'></label>
          </div>
          <div className='datos2'>
            <label className='datoslabel1'>ACTIVO</label>
            <label className='datoslabel2'></label>
            <label className='datoslabel1'>FECHA</label>
            <label className='datoslabel2'></label>
          </div>

          <div className='tituloBody'>
            <h4 className="h4Consultar">DATOS GENERALES</h4>
          </div>
        </div>


        <div className='datosGeneralesF2'>
            
        <div className='datosGenerales1'>
            <label className='datoslabel3'>NOMBRE DE EMPRESA</label>
            <label className='datoslabel2'></label>
          </div>

          <div className='datosGenerales2'>
            <label className='datoslabel3'>ENCARGADO DE EMPRESA</label>
            <label className='datoslabel2'></label>
          </div>

          <div className='datosGenerales3'>

          <label className='datoslabel3'>FECHA INICIO</label>
            <label className='datoslabel2'></label>
            <label className='datoslabel4'>FECHA FIN</label>
            <label className='datoslabel2'></label>
           
          </div>

        </div>


    <div className="contenedorTabla">
        <table>
            <thead>
                <tr>
                    <td>ACTIVIDAD</td>
                    <td>PERSONAL TÉCNICO</td>
                    <td>HORA INICIO</td>
                    <td>HORA FINAL</td>
                </tr>
            </thead>
        </table>
    </div>


    <div className="contenedorFirmas">

        <div className="contenedorFirma1"><img/><label className="labelFirma">Firma gerente o coordinador de servicio</label></div>
        <div className="contenedorFirma1"><img /><label className="labelFirma">Firma jefe o coordinador MAO </label></div>
        <div className="contenedorFirma1"><img /><label className="labelFirma">Firma coordinador de Unidad Funcional</label></div>

    </div>
        

      </body>
    </div>
  );
}

export default ConsultarOrdenFormato2;
