import React, { useEffect, useState, forwardRef } from "react";
import Nabvar2 from "./Navbar2";
import MaterialTable from "material-table";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ShareIcon from "@material-ui/icons/Share";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Cookies from "universal-cookie/es6";
import Login from "./Login";
import "../css/Loader.css";
import swal from "sweetalert";

const tableIcons = {
  SaveAlt: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  ArrowDownward: forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  RemoveRedEyeIcon: forwardRef((props, ref) => (
    <RemoveRedEyeIcon {...props} ref={ref} />
  )),
  ShareIcon: forwardRef((props, ref) => <ShareIcon {...props} ref={ref} />),
  CheckCircleOutlineOutlinedIcon: forwardRef((props, ref) => (
    <CheckCircleOutlineOutlinedIcon {...props} ref={ref} />
  )),
  RadioButtonUncheckedOutlinedIcon: forwardRef((props, ref) => (
    <RadioButtonUncheckedOutlinedIcon {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ArrowUpwardIcon: forwardRef((props, ref) => (
    <ArrowUpwardIcon {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function Viewer() {
  const [datos, setdatos] = useState([]);
  const api = "http://181.115.47.108:8001";
  const [mostrarLoader, setmostrarLoader] = useState("");
  const [mostrarTabla, setmostrarTabla] = useState("none");

  const cookies = new Cookies();

  const column = [
    { title: "N°", field: "Numero" },
    { title: "Familia", field: "familia" },
    { title: "Subfamilia", field: "subFamilia" },
    { title: "Categoría/ Partida", field: "categoria" },
    { title: "Descripcion", field: "descripcion" },
    {
      title: "Especificaciones/Características",
      field: "especificaciones",
      cellStyle: {
        width: "230px",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
    },
    { title: "Unidad", field: "unidad" },
    { title: "Marca", field: "marca" },
    { title: "Modelo", field: "modelo" },
    { title: "No Serie", field: "serie" },
    { title: "No. Inventario", field: "noInventario" },
    { title: "Grupo Funcional", field: "grupoFuncional" },
    { title: "Nivel", field: "nivel" },
    { title: "Institución", field: "institucion" },
    { title: "Estatus", field: "estatus" },
  ];

  const getData = async () => {
    const res = await fetch(`${api}/ConsultarBienesAfectos`);
    const data = await res.json();

    data.map((result) => {
      if (result.especificaciones) {
        // Verificar que especificaciones tiene un valor
        const primeros = result.especificaciones.substring(0, 40);
        result.especificaciones = primeros + "...";
      }
      return result;
    });

    setdatos(data);
    setmostrarLoader("none");
    setmostrarTabla("");
  };

  useEffect(() => {
    getData();
  }, []);

  if (cookies.get("codigoUsuario") !== undefined) {
    return (
      <React.Fragment>
        <Nabvar2></Nabvar2>

        <div
          className='contenedorSpinner'
          style={{ display: `${mostrarLoader}` }}
        >
          <div class='dot-spinner'>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
          </div>
        </div>

        <div style={{ Width: "100vw", display: `${mostrarTabla}` }}>
          <MaterialTable
            icons={tableIcons}
            title='Bienes Afectos'
            columns={column}
            data={datos}
            actions={[
              {
                icon: RemoveRedEyeIcon,
                tooltip: "Consultar Especificaciones",

                onClick: async (event, rowData) => {
                  const res = await fetch(
                    `${api}/Especificaciones?noinventario=${rowData["noInventario"]}`
                  );
                  const data = await res.json();
                  console.log(data);

                  return swal({
                    title: "Especificaciones",
                    text: data[0].especificaciones,
                    icon: "info",
                    button: "ok",
                  });
                },
              },
            ]}
            options={{
              filtering: true,
              pageSize: 20,
              pageSizeOptions: [20],
              toolbar: true,
              paging: true,
              rowStyle: {
                whiteSpace: "nowrap",
              },
              headerStyle: {
                whiteSpace: "nowrap",
              },
            }}
          />
        </div>
      </React.Fragment>
    );
  } else {
    return <Login></Login>;
  }
}

export default Viewer;
