import React, { useRef, useState, useEffect } from "react";
import "../css/Firmar.css";
import SignatureCanvas from "react-signature-canvas";
import { Button } from "react-bootstrap";
import Cookies from "universal-cookie/es6";
import swal from "sweetalert";
import "blueimp-canvas-to-blob";

const api = "http://181.115.47.108:8001";

function Firmar({ ...props }) {
  const sigCanvas = useRef(null);
  const [image, setimage] = useState("");

  const limpiar = () => sigCanvas.current.clear();

  const guardarFirma = () => {
    setimage(sigCanvas.current.toDataURL());
  };

  const crearFirma = async () => {
    const id = props.idOrden;
    const imagenValue = image;
    const tipoFirmaSeleccionada = props.tipoFirmaSeleccionada;
    console.log("enviando imagen" + imagenValue);
    const res = await fetch(`${api}/agregarFirma`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
        tipoFirmaSeleccionada,
        imagenValue,
      }),
    });

    const data = await res.json();

    swal({
      title: "Se Está Creando Firma",
      icon: "info",
      button: {
        text: "OK",
        closeModal: false,
      },
    }).then(async () => {
      if (data === true) {
        return swal({
          title: "Firma Creada",
          icon: "success",
          button: {
            text: "OK",
            closeModal: false,
          },
        }).then(async () => {
          const res2 = await fetch(
            `${api}/enviarCorreoMao?folio=${props.idOrden}`
          );

          const data2 = await res2.json();
          if (data2 === true) {
            return swal({
              title: "Correo Enviado",
              icon: "success",
              button: "OK",
            });
          } else {
            return swal({
              title: "Ha Ocurrido un Problema al Enviar Correo",
              icon: "error",
              button: "OK",
            });
          }
        });
      } else {
        return swal({
          title: "Ha Ocurrido un Error al Guardar Firma",
          icon: "error",
          button: "ok",
        });
      }
    });
  };

  return (
    <React.Fragment>
      <div className='contenedorCanvas'>
        <SignatureCanvas
          ref={sigCanvas}
          id='canvas'
          canvasProps={{ className: "sigCanvas" }}
        ></SignatureCanvas>
      </div>

      <div className='contenedorBotones'>
        <Button variant='warning' onClick={limpiar}>
          Limpiar
        </Button>
        <Button variant='success' onClick={guardarFirma}>
          Guardar
        </Button>
        <Button variant='success' onClick={crearFirma}>
          Firmar
        </Button>
      </div>
    </React.Fragment>
  );
}

export default Firmar;
