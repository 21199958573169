import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import imagen from "../assets/logoOperadora.png";
import "../css/Login.css";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie/es6";
import swal from "sweetalert";

const api = "http://181.115.47.108:8001";

function Login() {
  let navigate = useNavigate();
  const cookies = new Cookies();
  const [mensajeParrafo, setmensajeParrafo] = useState("");
  const [mostrarMensaje, setmostrarMensaje] = useState("none");
  const [correo, setcorreo] = useState("");
  const [contrasena, setcontrasena] = useState("");

  let mensaje = "";

  const iniciarSesion = async () => {
    if (correo === "" || contrasena === "") {
      return swal({
        title: "Todos los campos son obligatorios.",
        icon: "warning",
        button: "ok",
      });
    }

    const rest = await fetch(
      `${api}/Login?correo=${correo}&contrasena=${contrasena}`
    );

    const data = await rest.json();

    console.log(data);

    if (data === false) {
      mensaje = "No Realizó la Consulta";
      setmensajeParrafo(mensaje);
      setmostrarMensaje("");
    } else if (data === "No Existe Usuario") {
      mensaje = "No Existe Usuario";
      setmensajeParrafo(mensaje);
      setmostrarMensaje("");
    } else if (data === "Contraseña Incorrecta") {
      mensaje = "Contraseña Incorrecta";
      setmensajeParrafo(mensaje);
      setmostrarMensaje("");
    } else if (data.length > 0) {
      console.log(data);
      cookies.set("codigoUsuario", data[0].idUsuario, { path: "/" });
      cookies.set("nombre", data[0].nombre, { path: "/" });
      cookies.set("apellido", data[0].apellido, { path: "/" });
      cookies.set("correo", data[0].correoElectronico, { path: "/" });
      cookies.set("AreaTrabajo", data[0].idArea, { path: "/" });
      cookies.set("tipoFirma", data[0].firma, { path: "/" });
      cookies.set("correlativo", data[0].correlativo, { path: "/" });
      setmostrarMensaje("none");
      if (data[0].firma === "ViewerBienes") {
        return navigate("/bienesAfectosViewer");
      } else {
        return navigate("/dashboard");
      }
    }
  };

  return (
    <React.Fragment>
      <div className='contenedor-Login'>
        <h1 className='h1Login'>Control Para Gestión De Mantenimiento</h1>

        <div className='contenedorLogoFormulario'>
          <img src={imagen} alt='Logo Operadora' className='imagen-Login' />

          <div className='contenedorPrincipalFormulario'>
            <div className='contenedor-Formulario'>
              <label className='labelLogin'>Nombre Usuario</label>
              <Form.Control
                type='text'
                value={correo}
                onChange={(e) => {
                  setcorreo(e.target.value);
                }}
              ></Form.Control>
            </div>

            <div className='contenedor-Formulario'>
              <label className='labelLogin'>Contraseña</label>
              <Form.Control
                type='password'
                value={contrasena}
                onChange={(e) => {
                  setcontrasena(e.target.value);
                }}
              ></Form.Control>
            </div>

            <div className='contenedor-Boton'>
              <button className='boton-Logo' onClick={iniciarSesion}>
                Iniciar Sesión
              </button>
            </div>
            <div>
              <Link to='/crearCuenta'>¿No tienes una cuenta? Registrate</Link>
            </div>

            <div className='mensaje' style={{ display: `${mostrarMensaje}` }}>
              <p className='parrafoLogin'>{mensajeParrafo}</p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Login;
