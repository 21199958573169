import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ShareIcon from "@material-ui/icons/Share";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import CreateIcon from "@material-ui/icons/Create";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import swal from "sweetalert";
import Modal from "react-bootstrap/Modal";
import { FormLabel, FormSelect } from "react-bootstrap";
import Firmar from "./Firmar";
import QR from "./QR";
import Nabvar from "./Nabvar";
import Cookies from "universal-cookie/es6";
import Login from "./Login";

const tableIcons = {
  SaveAlt: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  ArrowDownward: forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  RemoveRedEyeIcon: forwardRef((props, ref) => (
    <RemoveRedEyeIcon {...props} ref={ref} />
  )),
  ShareIcon: forwardRef((props, ref) => <ShareIcon {...props} ref={ref} />),
  CheckCircleOutlineOutlinedIcon: forwardRef((props, ref) => (
    <CheckCircleOutlineOutlinedIcon {...props} ref={ref} />
  )),
  RadioButtonUncheckedOutlinedIcon: forwardRef((props, ref) => (
    <RadioButtonUncheckedOutlinedIcon {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ArrowUpwardIcon: forwardRef((props, ref) => (
    <ArrowUpwardIcon {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function EquipoData() {
  const navigate = useNavigate();
  const cookies = new Cookies();

  const [datos, setdatos] = useState([]);

  const [mostrarModal, setmostrarModal] = useState(false);
  const [mostrarModal2, setmostrarModal2] = useState(false);
  const [tipoFirma, setTipoFirma] = useState([]);
  const [tipoFirmaSeleccionada, setTipoFirmaSeleccionada] = useState([]);
  const [firma, setFirma] = useState([]);
  const [idOrden, setidOrden] = useState();
  const [value, setValue] = useState();
  const api = "http://181.115.47.108:8001";
  const api2 = "http://181.115.47.108";

  const getOrden = async () => {
    const res = await fetch(
      `${api}/consultaOrdenTrabajo?area=${cookies.get(
        "AreaTrabajo"
      )}?tipoFirma=${tipoFirma}`
    );
    const data = await res.json();
    data.map((result) => {
      result["Folio"] = cookies.get("correlativo") + "-" + result["Folio"];
      result["Fecha Inicio"] = result["Fecha Inicio"].slice(0, 10);
      result["Fecha Fin"] = result["Fecha Fin"].slice(0, 10);
      return result;
    });
    setdatos(data);
  };

  const column = [
    { title: "Código", field: "Codigo" },
    { title: "Folio", field: "Folio" },
    { title: "Area", field: "nombreArea" },
    { title: "Servicio", field: "Servicio" },
    { title: "Edificio", field: "Edificio" },
    { title: "Nivel", field: "nivel" },
    { title: "Activo", field: "nombreActivo" },
    { title: "Fecha Inicio", field: "Fecha Inicio" },
    { title: "Fecha Fin", field: "Fecha Fin" },
    { title: "Estado", field: "estadoFirma" },
  ];

  const getTipoFirma = async () => {
    const tp = cookies.get("tipoFirma");
    console.log("tipoFirma:" + tp);
    const res = await fetch(`${api}/consultarTipoFirma?tipoFirma=${tp}`);
    const data = await res.json();
    setTipoFirma(data);
  };

  useEffect(() => {
    getOrden();
  }, []);

  if (cookies.get("codigoUsuario") !== undefined) {
    return (
      <React.Fragment>
        <Nabvar></Nabvar>
        <div style={{ maxWidth: "100vw" }}>
          <MaterialTable
            icons={tableIcons}
            title='Ordenes Programadas'
            columns={column}
            data={datos}
            actions={[
              {
                icon: CreateIcon,
                tooltip: "Firmar Orden de Trabajo",
                onClick: async (event, rowData) => {
                  if (cookies.get("tipoFirma") !== "Sin autorización") {
                    const res = await fetch(
                      `${api}/consultarFirmasOrden?folio=${
                        rowData["Folio"]
                      }&tipoFirma=${cookies.get("tipoFirma")}`
                    );

                    const data = await res.json();

                    if (data.length > 0) {
                      swal({
                        title: "Ya se Registró " + cookies.get("tipoFirma"),
                        icon: "info",
                        button: {
                          text: "OK",
                          closeModal: true,
                        },
                      });
                    } else {
                      setmostrarModal(true);
                      getTipoFirma();
                      setidOrden(rowData["Codigo"]);
                    }
                  } else {
                    swal({
                      title: "Usuario no Autorizado para Firmar",
                      icon: "error",
                      button: {
                        text: "OK",
                        closeModal: true,
                      },
                    });
                  }
                },
              },
              {
                icon: RemoveRedEyeIcon,
                tooltip: "Ver Orden de Trrabajo",
                onClick: (event, rowData) => {
                  return navigate(`/consulta/${rowData["Codigo"]}`);
                },
              },
              {
                icon: ShareIcon,
                tooltip: "Compartir Orden Programada",
                onClick: (event, rowData) => {
                  setmostrarModal2(true);
                  setValue(rowData["Codigo"]);
                },
              },
              {
                icon: () => <Button variant='success'>Agregar</Button>,
                tooltip: "Add User",
                isFreeAction: true,
                onClick: (event) => navigate("/agregarOrdenProgramada"),
              },
            ]}
            options={{
              filtering: true,
              actionsColumnIndex: -1,
              pageSize: 5,
              pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
              toolbar: true,
              paging: true,
              rowStyle: {
                whiteSpace: "nowrap",
              },
            }}
          />
        </div>

        <div>
          <Modal
            size='lg'
            show={mostrarModal}
            onHide={() => setmostrarModal(false)}
            aria-labelledby='example-modal-sizes-title-lg'
          >
            <Modal.Header closeButton>
              <Modal.Title id='example-modal-sizes-title-lg'>
                Agregar Firma a Orden de Trabajo
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormLabel>Tipo de Firma</FormLabel>
              <FormSelect
                type='time'
                className='letra m-0'
                value={tipoFirmaSeleccionada}
                onChange={(e) => setTipoFirmaSeleccionada(e.target.value)}
              >
                <option value=''>Seleccione una Opción</option>
                {tipoFirma.map((result) => (
                  <option value={result.idTipoFirma}>{result.firma}</option>
                ))}
              </FormSelect>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <Firmar
                  tipoFirmaSeleccionada={tipoFirmaSeleccionada}
                  Firma={setFirma}
                  firma={firma}
                  idOrden={idOrden}
                ></Firmar>
              </div>
            </Modal.Body>
          </Modal>
        </div>

        <Modal
          size='lg'
          show={mostrarModal2}
          onHide={() => setmostrarModal2(false)}
          aria-labelledby='example-modal-sizes-title-lg'
        >
          <Modal.Header closeButton>
            <Modal.Title id='example-modal-sizes-title-lg'>
              Compartir Orden de Trabajo por QR
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <QR value={api2 + "/consulta/" + value}></QR>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  } else {
    return <Login></Login>;
  }
}

export default EquipoData;
