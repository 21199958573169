import React, { useEffect, useState, forwardRef, useRef } from "react";
import Nabvar from "./Nabvar";
import MaterialTable from "material-table";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ShareIcon from "@material-ui/icons/Share";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import Button from "react-bootstrap/Button";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import CommentIcon from "@material-ui/icons/Comment";
import "../css/Loader.css";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Modal from "react-bootstrap/Modal";
import { FormControl, FormLabel } from "react-bootstrap";
import { Edit } from "@material-ui/icons";
import swal from "sweetalert";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";

const tableIcons = {
  SaveAlt: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  ArrowDownward: forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  RemoveRedEyeIcon: forwardRef((props, ref) => (
    <RemoveRedEyeIcon {...props} ref={ref} />
  )),
  ShareIcon: forwardRef((props, ref) => <ShareIcon {...props} ref={ref} />),
  CheckCircleOutlineOutlinedIcon: forwardRef((props, ref) => (
    <CheckCircleOutlineOutlinedIcon {...props} ref={ref} />
  )),
  RadioButtonUncheckedOutlinedIcon: forwardRef((props, ref) => (
    <RadioButtonUncheckedOutlinedIcon {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ArrowUpwardIcon: forwardRef((props, ref) => (
    <ArrowUpwardIcon {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  CommentIcon: forwardRef((props, ref) => <CommentIcon {...props} ref={ref} />),
};

function BienesAfectos() {
  const tableRef = useRef(null);
  const navigate = useNavigate();

  const [datos, setDatos] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState({});

  const [mostrarLoader, setmostrarLoader] = useState("");
  const [mostrarTabla, setmostrarTabla] = useState("none");

  const [file, setFile] = useState(null);
  const [mostrarModal, setmostrarModal] = useState(false);
  const [peticion, setPeticionl] = useState(false);
  const [ocultar, setOcultar] = useState([]); // Inicialmente, ocultar es un arreglo vacío

  const [data, setData] = useState([]);

  const api = "http://181.115.47.108:8001";

  const column = [
    { title: "N°", field: "Numero" },
    { title: "Familia", field: "familia" },
    { title: "Subfamilia", field: "subFamilia" },
    { title: "Categoría/ Partida", field: "categoria" },
    { title: "Descripcion", field: "descripcion" },
    { title: "Especificaciones/Características", field: "especificaciones" },
    { title: "Unidad", field: "unidad" },
    { title: "Marca", field: "marca" },
    { title: "Modelo", field: "modelo" },
    { title: "No Serie", field: "serie" },
    { title: "No. Inventario", field: "noInventario" },
    { title: "Código de Plano", field: "codigoPlano" },
    { title: "Grupo Funcional", field: "grupoFuncional" },
    { title: "Nivel", field: "nivel" },
    { title: "Institución", field: "institucion" },
    { title: "Puesto en Marcha", field: "puestoMarcha" },
    { title: "Estatus", field: "estatus" },
  ];

  const [items, setItems] = useState(column);

  const getData = async () => {
    setmostrarLoader("");
    setmostrarTabla("none");

    const res = await fetch(`${api}/ConsultarBienesAfectos`);
    const json = await res.json();
    const data = json.results;
    console.log("cantidad" + json.totalRecords);
    setTotalRecords(json.totalRecords);

    data.map((result) => {
      if (result.especificaciones) {
        // Verificar que especificaciones tiene un valor
        const primeros = result.especificaciones.substring(0, 40);
        result.especificaciones = primeros + "...";
      }

      return result;
    });

    setDatos(data);
    setmostrarLoader("none");
    setmostrarTabla("");
  };

  useEffect(() => {
    getData();
  }, [page, pageSize, filters]);

  const enviarArchivo = async () => {
    if (file === null) {
      alert("No ha seleccionado ningun archivo");
      return "";
    }

    const formData = new FormData();
    formData.append("file", file);

    console.log(formData);

    const resp = await fetch(`${api}/bienesAfecto`, {
      method: "POST",
      body: formData,
    });

    const data = await resp.json();

    if (data[0] === true) {
      alert(
        "registros agregados: " +
          data[1] +
          ",registros actualizados: " +
          data[2]
      );
      setPeticionl(!peticion);
    } else {
      alert(
        "Error al cargar los registros, verifique no hayan No Inventario repetidos y que las columnas coincidan"
      );
    }
  };

  const handleDelete = (field) => {
    setOcultar((prevOcultar) => [...prevOcultar, field]); // Agregar field al arreglo ocultar

    const updatedColumns = column.filter(
      (column) => !ocultar.includes(column.field)
    );
    setItems(updatedColumns);

    const updatedData = datos.map((row) => {
      const updatedRow = { ...row };
      delete updatedRow[field];
      return updatedRow;
    });
    setDatos(updatedData);
  };

  return (
    <React.Fragment>
      <Nabvar></Nabvar>

      <div
        className='contenedorSpinner'
        style={{ display: `${mostrarLoader}` }}
      >
        <div class='dot-spinner'>
          <div class='dot-spinner__dot'></div>
          <div class='dot-spinner__dot'></div>
          <div class='dot-spinner__dot'></div>
          <div class='dot-spinner__dot'></div>
          <div class='dot-spinner__dot'></div>
          <div class='dot-spinner__dot'></div>
          <div class='dot-spinner__dot'></div>
          <div class='dot-spinner__dot'></div>
        </div>
      </div>

      <div style={{ display: `${mostrarTabla}` }}>
        <div className='container-fluid d-flex flex-wrap mt-1'>
          {items.map((result, index) => (
            <div>
              <button
                key={result.field}
                className='btn btn-secondary mb-2 mx-2'
              >
                {result.title}{" "}
                <span
                  class='badge bg-danger'
                  onClick={() => handleDelete(result.field)}
                >
                  {" "}
                  X
                </span>
              </button>
            </div>
          ))}
        </div>
        <MaterialTable
          icons={tableIcons}
          title='Bienes Afectos'
          columns={items}
          data={datos}
          tableRef={tableRef}
          totalCount={totalRecords}
          actions={[
            {
              icon: () => <Button variant='primary'>Agregar</Button>,
              tooltip: "Agregar",
              isFreeAction: true,
              onClick: (event) => setmostrarModal(true),
            },
            {
              icon: () => <Button variant='success'>Exportar XLS</Button>,
              tooltip: "Exportar datos de Tabla",
              isFreeAction: true,
              onClick: (event) => {
                const datos = tableRef.current.dataManager.filteredData;
                const worksheet = XLSX.utils.json_to_sheet(datos);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
                //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
                XLSX.writeFile(workbook, `Reporte Bienes Afecto.xlsx`);
              },
            },

            {
              icon: Edit,
              tooltip: "Editar Estado",
              onClick: async (event, rowData) => {
                const res = await fetch(
                  `${api}/EditarEstadoBien?noInventario=${rowData["noInventario"]}&estado=${rowData["estatus"]}`
                );
                const data = await res.json();
                console.log("validando" + data);
                if (data === true) {
                  alert("Actualización de estatus realizado correctamente");
                  setPeticionl(!peticion);
                } else {
                  alert("Error al cambiar estatus");
                }
              },
            },
            {
              icon: CommentIcon,
              tooltip: "Consultar Especificaciones",

              onClick: async (event, rowData) => {
                const res = await fetch(
                  `${api}/Especificaciones?noinventario=${rowData["noInventario"]}`
                );
                const data = await res.json();
                console.log(data);

                return swal({
                  title: "Especificaciones",
                  text: data[0].especificaciones,
                  icon: "info",
                  button: "ok",
                });
              },
            },
            {
              icon: RemoveRedEyeIcon,
              tooltip: "Consultar Bien Afecto",

              onClick: async (event, rowData) => {
                return navigate(`/bienAfecto/${rowData["noInventario"]}`);
              },
            },
          ]}
          options={{
            filtering: true,
            actionsColumnIndex: -1,
            emptyRowsWhenPaging: false,
            paging: true,
            pageSize: 20, // Cantidad de filas por página
            pageSizeOptions: [10, 20, 30, 50, 75, 100],
            rowStyle: {
              whiteSpace: "nowrap",
            },
            headerStyle: {
              whiteSpace: "nowrap",
            },
          }}
        />
      </div>

      <div>
        <Modal
          size='lg'
          show={mostrarModal}
          onHide={() => setmostrarModal(false)}
          aria-labelledby='example-modal-sizes-title-lg'
        >
          <Modal.Header closeButton>
            <Modal.Title id='example-modal-sizes-title-lg'>
              Importar Datos Excel
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormLabel>Adjuntar documento Excel</FormLabel>
            <FormControl
              type='file'
              name='archivo'
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
            />
            <Button
              variant='success'
              onClick={() => enviarArchivo()}
              className='mt-3'
            >
              Guardar Datos
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    </React.Fragment>
  );
}

export default BienesAfectos;
