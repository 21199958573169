import React, { useState, useEffect } from "react";
import "../css/Dashboard.css";
import TarjetaDashboard from "./TarjetaDashboard";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import Cookies from "universal-cookie/es6";
const api = "http://181.115.47.108:8001";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const options = { responsive: true };

function Dashboard() {
  const fecha = new Date();
  const [contador, setcontador] = useState([]);
  const [Label1, setlabel1] = useState([]);
  const [datos1, setdatos1] = useState([]);
  const [Label2, setlabel2] = useState([]);
  const [datos2, setdatos2] = useState([]);
  const [Label3, setlabel3] = useState([]);
  const [datos3, setdatos3] = useState([]);
  const [mes, setmes] = useState(fecha.getMonth());

  const cookies = new Cookies();

  const data = {
    datasets: [
      {
        label: "ORDENES DE TRABAJO MENSUAL POR ÁREA",
        data: datos1,
        backgroundColor: [
          //Color de fondo para el gráfico de líneas. Se pueden utilizar colores hexadecimales o nombres de colores predefinidos.
          "rgba(255, 99, 132, 0.2)", //Rojo claro
        ],
        borderColor: [
          //Colores bordes para el gráfico de líneas. Se pueden utilizar colores hexadecimales o nombres de colores predefinidos.

          "rgba(255, 99 ,132 ,1)", //Rojo oscuro
        ],
      },
    ],
    labels: Label1,
  };

  const data2 = {
    datasets: [
      {
        label: "ORDENES DE TRABAJO TOTALES",
        data: datos2,
        backgroundColor: [
          //Color de fondo para el gráfico de líneas. Se pueden utilizar colores hexadecimales o nombres de colores predefinidos.
          "rgba(54, 162, 235, 0.6)",
        ],
        borderColor: ["rgba(54, 162, 235, 0.6)"],
      },
    ],
    labels: Label2,
  };

  const data3 = {
    datasets: [
      {
        label: "ORDENES DE TRABAJO POR EDIFICIO",
        data: datos3,
        backgroundColor: [
          //Color de fondo para el gráfico de líneas. Se pueden utilizar colores hexadecimales o nombres de colores predefinidos.
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
        ],
      },
    ],
    labels: Label3,
  };

  useEffect(() => {
    const getData = async () => {
      const res = await fetch(
        api + "/Dashboard?area=" + cookies.get("AreaTrabajo") + "&mes=" + mes
      );

      const data = await res.json();
      console.error(data[0]);
      const array = data[0];
      const array2 = [0, 0, 0, 0];

      array.map((result) => {
        if (result.nombreArea === "IT") {
          array2[0] = result.cantidad;
          return "";
        } else if (result.nombreArea === "Infraestructura") {
          array2[1] = result.cantidad;
          return "";
        } else if (result.nombreArea === "Mantenimiento Electromecánico") {
          array2[1] = result.cantidad;
          return "";
        } else if (result.nombreArea === "Servicios Generales") {
          array2[1] = result.cantidad;
          return "";
        }
        return "";
      });

      setcontador(array2);
      const fecha1 = [];
      const datosFecha1 = [];

      const fecha2 = [];
      const datosFecha2 = [];

      const fecha3 = [];
      const datosFecha3 = [];

      console.log(data[1]);

      data[1].map((result) => {
        fecha1.push(result.fecha.slice(8, 10));
        datosFecha1.push(result.cantidad);
        return "";
      });

      data[2].map((result) => {
        fecha2.push(result.fecha.slice(8, 10));
        datosFecha2.push(result.cantidad);
        return "";
      });

      data[3].map((result) => {
        fecha3.push(result.nombreEdificio);
        datosFecha3.push(result.cantidad);
        return "";
      });

      setlabel1(fecha1);
      setdatos1(datosFecha1);
      setlabel2(fecha2);
      setdatos2(datosFecha2);
      setlabel3(fecha3);
      setdatos3(datosFecha3);
    };

    getData();
  }, [mes]);

  return (
    <React.Fragment>
      <div className='contenedorDashboard'>
        <main className='dashboardMain' style={{ heigth: "2vh" }}>
          <div style={{ marginRight: "20px" }}>
            <select
              style={{ width: "150px", heigth: "50px" }}
              value={mes}
              onChange={(e) => setmes(e.target.value)}
            >
              <option value='0'>ENERO</option>
              <option value='1'>FEBRERO</option>
              <option value='2'>MARZO</option>
              <option value='3'>ABRIL</option>
              <option value='4'>MAYO</option>
              <option value='5'>JUNIO</option>
              <option value='6'>JULIO</option>
              <option value='7'>AGOSTO</option>
              <option value='8'>SEPTIEMBRE</option>
              <option value='9'>OCTUBRE</option>
              <option value='10'>NOVIEMBRE</option>
              <option value='11'>DICIEMBRE</option>
            </select>
          </div>
        </main>

        <body className='bodyDashboard' style={{ heigth: "97vh" }}>
          <div className='tarjetas' style={{ heigth: "10vh" }}>
            <TarjetaDashboard
              contador={contador[0] ? contador[0] : 0}
              titulo='IT'
            />
            <TarjetaDashboard
              contador={contador[1] ? contador[1] : 0}
              titulo='Infraestructura'
            />
            <TarjetaDashboard
              contador={contador[2] ? contador[2] : 0}
              titulo='Electromecanica'
            />
            <TarjetaDashboard
              contador={contador[3] ? contador[3] : 0}
              titulo='Servicios Generales'
            />
          </div>

          <div className='contenedorGraficos'>
            <div className='primerGrafico'>
              <div>
                <Bar data={data} options={options}></Bar>
              </div>

              <div>
                <Line data={data2} options={options}></Line>
              </div>
            </div>

            <div className='segundoGrafico'>
              <div>
                {" "}
                <Doughnut data={data3} options={options}></Doughnut>
              </div>
            </div>
          </div>
        </body>
      </div>
    </React.Fragment>
  );
}

export default Dashboard;
