import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import crearCuentaModel from "../Models/crearCuentaModel";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
const api = "http://181.115.47.108:8001";

function CrearCuenta() {
  const navigate = useNavigate();
  const [nombre, setnombre] = useState("");
  const [apellido, setapellido] = useState("");
  const [correo, setcorreo] = useState("");
  const [contrasena, setcontrasena] = useState("");
  const [confirmarContrasena, setconfirmarContrasena] = useState("");
  const [area, setarea] = useState("");

  const guardarUsuario = async () => {
    if (contrasena.length < 8) {
      alert("Contraseña tiene que tener más de 8");
      return;
    }

    if (confirmarContrasena !== contrasena) {
      alert("La contraseña y confirmacion deben de ser igual");
      return;
    }

    if (
      nombre === "" ||
      apellido === "" ||
      correo === "" ||
      contrasena === "" ||
      confirmarContrasena === "" ||
      area === ""
    ) {
      alert("Todos los campos son obligatorios");
      return;
    }

    const datos = new crearCuentaModel(
      nombre,
      apellido,
      correo,
      contrasena,
      area
    );

    const res = await fetch(`${api}/crearCuenta`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        datos,
      }),
    });

    const data = await res.json();
    console.log(data);
    if (data === true) {
      return navigate(`/`);
    } else {
      return alert("No se pudo crear el usuario");
    }
  };

  return (
    <React.Fragment>
      <Form.Group className='mb-3'>
        <Form.Label>Nombre</Form.Label>
        <Form.Control
          value={nombre}
          onChange={(e) => {
            setnombre(e.target.value);
          }}
        />
      </Form.Group>

      <Form.Group className='mb-3'>
        <Form.Label>Apellido</Form.Label>
        <Form.Control
          value={apellido}
          onChange={(e) => {
            setapellido(e.target.value);
          }}
        />
      </Form.Group>

      <Form.Group className='mb-3'>
        <Form.Label>Correo Electrónico</Form.Label>
        <Form.Control
          value={correo}
          onChange={(e) => {
            setcorreo(e.target.value);
          }}
        />
      </Form.Group>

      <Form.Group className='mb-3'>
        <Form.Label>Contraseña</Form.Label>
        <Form.Control
          type='password'
          value={contrasena}
          onChange={(e) => {
            setcontrasena(e.target.value);
          }}
        />
      </Form.Group>

      <Form.Group className='mb-3'>
        <Form.Label>Confirmar Contraseña</Form.Label>
        <Form.Control
          type='password'
          value={confirmarContrasena}
          onChange={(e) => {
            setconfirmarContrasena(e.target.value);
          }}
        />
      </Form.Group>

      <Form.Group className='mb-3'>
        <Form.Label>Área de Trabajo</Form.Label>
        <Form.Select
          value={area}
          onChange={(e) => {
            setarea(e.target.value);
          }}
        >
          <option value=''>Seleccione una Opción</option>
          <option value='1'>IT</option>
          <option value='2'>Infraestructura</option>
          <option value='3'>Servicios Operativos</option>
          <option value='4'>Mantenimiento Electromecánico</option>
        </Form.Select>

        <div className='d-grid m-3'>
          <Button variant='primary' size='lg' onClick={guardarUsuario}>
            Crear Cuenta
          </Button>
        </div>
      </Form.Group>
    </React.Fragment>
  );
}

export default CrearCuenta;
