import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
const api = "http://181.115.47.108:8001";

function ConsultaBienAfecto() {
  const { noInventario } = useParams();
  const navigate = useNavigate();

  const [familia, setfamilia] = useState("");
  const [subFamilia, setsubFamilia] = useState("");
  const [categoria, setcategoria] = useState("");
  const [descripcion, setdescripcion] = useState("");
  const [especificaciones, setespecificaciones] = useState("");
  const [unidad, setunidad] = useState("");
  const [marca, setmarca] = useState("");
  const [modelo, setmodelo] = useState("");
  const [serie, setserie] = useState("");
  const [noInventario2, setnoInventario2] = useState("");
  const [codigoPlano, setcodigoPlano] = useState("");
  const [grupoFuncional, setgrupoFuncional] = useState("");
  const [nivel, setnivel] = useState("");
  const [institucion, setinstitucion] = useState("");
  const [puestoMarcha, setpuestoMarcha] = useState("");
  const [estatus, setestatus] = useState("");

  const getBien = async () => {
    console.log(noInventario);
    const res = await fetch(
      `${api}/Especificaciones?noinventario=${noInventario}`
    );
    const data = await res.json();

    if (data.length === 0) {
      return alert("No se encontró bien");
    }
    setfamilia(data[0].familia);
    setsubFamilia(data[0].subFamilia);
    setcategoria(data[0].categoria);
    setdescripcion(data[0].descripcion);
    setespecificaciones(data[0].especificaciones);
    setunidad(data[0].unidad);
    setmarca(data[0].marca);
    setmodelo(data[0].modelo);
    setserie(data[0].serie);
    setnoInventario2(data[0].Inventario2);
    setcodigoPlano(data[0].codigoPlano);
    setgrupoFuncional(data[0].grupoFuncional);
    setnivel(data[0].nivel);
    setinstitucion(data[0].institucion);
    setpuestoMarcha(data[0].puestoMarcha);
    setestatus(data[0].estatus);
  };

  useEffect(() => {
    getBien();
  }, []);

  return (
    <React.Fragment>
      <div style={{ width: "100vw", heigth: "auto" }}>
        <div className='container-fluid'>
          <div className='row'>
            <Form.Group className='mb-3 col'>
              <Form.Label>Familia</Form.Label>
              <Form.Control placeholder={familia} disabled />
            </Form.Group>
            <Form.Group className='mb-3 col'>
              <Form.Label>SubFamilia</Form.Label>
              <Form.Control placeholder={subFamilia} disabled />
            </Form.Group>
            <Form.Group className='mb-3 col'>
              <Form.Label>Categoria Partida</Form.Label>
              <Form.Control placeholder={categoria} disabled />
            </Form.Group>
          </div>

          <div className='row'>
            <Form.Group className='mb-3 col'>
              <Form.Label>Descripcion</Form.Label>
              <Form.Control placeholder={descripcion} disabled />
            </Form.Group>
            <Form.Group className='mb-3 col'>
              <Form.Label>Especificaciones</Form.Label>
              <Form.Control
                style={{ height: "100px" }}
                placeholder={especificaciones}
                disabled
                as='textarea'
              />
            </Form.Group>
          </div>

          <div className='row'>
            <Form.Group className='mb-3 col'>
              <Form.Label>Unidad</Form.Label>
              <Form.Control placeholder={unidad} disabled />
            </Form.Group>
            <Form.Group className='mb-3 col'>
              <Form.Label>Marca</Form.Label>
              <Form.Control placeholder={marca} disabled />
            </Form.Group>
            <Form.Group className='mb-3 col'>
              <Form.Label>Modelo</Form.Label>
              <Form.Control placeholder={modelo} disabled />
            </Form.Group>
          </div>

          <div className='row'>
            <Form.Group className='mb-3 col'>
              <Form.Label>No Serie</Form.Label>
              <Form.Control placeholder={serie} disabled />
            </Form.Group>
            <Form.Group className='mb-3 col'>
              <Form.Label>No Inventario</Form.Label>
              <Form.Control placeholder={noInventario2} disabled />
            </Form.Group>
            <Form.Group className='mb-3 col'>
              <Form.Label>Código de Plano</Form.Label>
              <Form.Control placeholder={codigoPlano} disabled />
            </Form.Group>
          </div>

          <div className='row'>
            <Form.Group className='mb-3 col'>
              <Form.Label>Grupo Funcional</Form.Label>
              <Form.Control placeholder={grupoFuncional} disabled />
            </Form.Group>
            <Form.Group className='mb-3 col'>
              <Form.Label>Nivel</Form.Label>
              <Form.Control placeholder={nivel} disabled />
            </Form.Group>
            <Form.Group className='mb-3 col'>
              <Form.Label>Institución</Form.Label>
              <Form.Control placeholder={institucion} disabled as='textarea' />
            </Form.Group>
          </div>

          <div className='row'>
            <Form.Group className='mb-3 col'>
              <Form.Label>Puesto en Marcha</Form.Label>
              <Form.Control placeholder={puestoMarcha} disabled />
            </Form.Group>
            <Form.Group className='mb-3 col'>
              <Form.Label>Estatus</Form.Label>
              <Form.Control
                className={
                  estatus === "ALTA"
                    ? "border border-success"
                    : "border border-warning"
                }
                placeholder={estatus}
                disabled
              />
            </Form.Group>
          </div>

          <div className='row'>
            <Button
              variant='primary'
              className='mb-3 col'
              onClick={() => {
                return navigate("/bienesAfectos");
              }}
            >
              Regresar a Bienes Afectos
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ConsultaBienAfecto;
