import React,{useState,useEffect} from "react";
import "../css/Dashboard.css";
import logoIT from "../assets/computer_FILL0_wght400_GRAD0_opsz48.svg"
import logoServ from "../assets/groups_FILL0_wght400_GRAD0_opsz48.svg"
import logoInfra from "../assets/handyman_FILL0_wght400_GRAD0_opsz48.svg"
import logoElectro from "../assets/recent_patient_FILL0_wght400_GRAD0_opsz48.svg"



function TarjetaDashboard({...props}) {
  const [clase, setclase] = useState("")
  const [clase2, setclase2] = useState("")
  const [logo, setlogo] = useState("")
  


useEffect(() => {

  if(props.titulo==="IT"){
    setclase("contenedorTarjetaTituloIT")
    setclase2("segundoContenedorIT")
    setlogo(logoIT)
    
  }else if(props.titulo==="Infraestructura"){
    setclase("contenedorTarjetaTituloINFRA")
    setclase2("segundoContenedorINFRA")
    setlogo(logoInfra)
    
   }else if(props.titulo==="Electromecanica"){
    setclase("contenedorTarjetaTituloELECTRO")
    setclase2("segundoContenedorELECTRO")
    setlogo(logoElectro)
  
   }else{
    setclase("contenedorTarjetaTituloSERV")
    setclase2("segundoContenedorSERV")
    setlogo(logoServ)
   
   }

}, [props.titulo])

 
  return (
    <div className='tarjeta'>
      <div className='primerContenedor'>
        <div className={clase}>
          <label>{props.contador}</label>
          <label>ORDENES DE TRABAJO</label>
        </div>
        <div className="icono">
          <label><img src={logo} alt="Iconos"/></label>
        </div>
      </div>

      <div className={clase2}>
        <label className="labelFooter">{props.titulo}</label>
      </div>

    </div>
  );
}

export default TarjetaDashboard;
