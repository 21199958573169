import React from "react";
import "../css/Navbar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../assets/logoOperadora.png";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie/es6";

function Navbar2() {
  const cookies = new Cookies();
  let navigate = useNavigate();

  const cerrarSesion = () => {
    cookies.remove("codigoUsuario");
    cookies.remove("nombre");
    cookies.remove("apellido");
    cookies.remove("correo");
    cookies.remove("AreaTrabajo");
    cookies.remove("tipoFirma");
    return navigate("/");
  };

  return (
    <React.Fragment>
      <Navbar expand='lg' style={{ backgroundColor: "#2c3e50" }} >
        <img className='imagen-Logo' src={logo} alt='logo' />
        <Navbar.Brand>
          <NavLink className='navbar-brand' to='#' style={{ color: "white" }}>
            Operadora CC
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='me-auto'>
            <NavLink className='nav-link' to='/bienesAfectosViewer' style={{ color: "white" }}>
              Bienes Afectos
            </NavLink>
          </Nav>
          <Nav>
            <label
              className='nav-link'
              style={{ color: "white" }}
              onClick={() => {
                cerrarSesion();
              }}
            >
              Cerrar Sesión
            </label>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </React.Fragment>
  );
}

export default Navbar2;
