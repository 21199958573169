import React, { useEffect, useState } from "react";
import "../css/AgregarOrden.css";
import Table from "react-bootstrap/Table";
import { FormControl, FormLabel, FormSelect } from "react-bootstrap";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Login from "./Login";

import Cookies from "universal-cookie/es6";

const api = "http://181.115.47.108:8001";

function AgregarOrdenProgramada() {
  const navigate = useNavigate();

  const cookies = new Cookies();
  const [mostarFormulario, setmostarFormulario] = useState("none");
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [tituloModal, setTituloModal] = useState("");
  const [datoAgregar, setDatoAgregar] = useState("");
  const [datoAgregar2, setDatoAgregar2] = useState("");
  const [selectAgregar, setselectAgregar] = useState("");

  const [mostarAgregar, setmostarAgregar] = useState("");
  const [servicio, setServicio] = useState([]);
  const [activo, setActivo] = useState([]);
  const [edificio, setEdificio] = useState([]);
  const [nivel, setNivel] = useState([]);
  const [actividades, setActividades] = useState([]);
  const [actividadesSeleccionada, setActividadesSeleccionada] = useState([]);
  const [persona, setPersona] = useState([]);
  const [personaSeleccionada, setPersonaSeleccionada] = useState([]);
  const [fechaActividad, setfechaActividad] = useState("");
  const [horaActividad, sethoraActividad] = useState("");
  const [contador, setContador] = useState(1);
  const [texto, setTexto] = useState("");
  const [texto2, setTexto2] = useState("");

  const [servicioSeleccionado, setServicioSeleccionado] = useState("");
  const [activoSeleccionado, setActivoSeleccionado] = useState("");
  const [edificioSeleccionado, setEdificioSeleccionado] = useState();
  const [nivelSeleccionado, setNivelSeleccionado] = useState("");
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [empresaEncargada, setEmpresaEncargada] = useState("");
  const [encargadoEmpresa, setEncargadoEmpresa] = useState("");
  const [arrayActividades, setarrayActividades] = useState([]);

  const fechaHoy = new Date();
  let mes;
  if (fechaHoy.getMonth() < 10) {
    mes = "0" + parseInt(fechaHoy.getMonth() + 1);
  } else {
    mes = parseInt(fechaHoy.getMonth() + 1);
  }

  let dia;
  if (fechaHoy.getDate() < 10) {
    dia = "0" + fechaHoy.getDate();
  } else {
    dia = fechaHoy.getDate();
  }

  const getDatos = async () => {
    const idArea = cookies.get("AreaTrabajo");

    const res = await fetch(`${api}/consultaDatosFormularios?area=${idArea}`);
    const data = await res.json();
    console.log(data);

    setServicio(data[0]);
    setEdificio(data[1]);
    setActivo(data[2]);
    setActividades(data[3]);
    setPersona(data[4]);
  };

  const llenarNivel = async (id_Edificio) => {
    const res = await fetch(`${api}/consultaNivel`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id_Edificio,
      }),
    });

    const data = await res.json();
    setNivel(data);
  };

  const crearOrdenTrabajo = async () => {
    const idUsuario = cookies.get("codigoUsuario");
    const idArea = cookies.get("AreaTrabajo");

    if (
      servicioSeleccionado === "" ||
      activoSeleccionado === "" ||
      edificioSeleccionado === "" ||
      nivelSeleccionado === "" ||
      fechaInicio === "" ||
      fechaFin === "" ||
      empresaEncargada === "" ||
      encargadoEmpresa === "" ||
      arrayActividades.length === 0
    ) {
      return swal({
        title: "Todos los campos son obligatorios.",
        icon: "warning",
        button: "ok",
      });
    }

    const res = await fetch(`${api}/crearOrdenTrabajo`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        servicioSeleccionado,
        activoSeleccionado,
        edificioSeleccionado,
        nivelSeleccionado,
        fecha: fechaHoy.getFullYear() + "-" + mes + "-" + dia,
        fechaInicio,
        fechaFin,
        empresaEncargada,
        encargadoEmpresa,
        arrayActividades,
        idUsuario,
        idArea,
      }),
    });

    const data = await res.json();

    swal({
      title: "Se Está Creando Orden de Trabajo",
      icon: "info",
      button: {
        text: "OK",
        closeModal: false,
      },
    }).then(async () => {
      if (data !== false) {
        console.log(data);
        return swal({
          title: "Orden de Trabajo Creada",
          icon: "success",
          button: {
            text: "OK",
            closeModal: false,
          },
        }).then(async () => {
          const res2 = await fetch(
            `${api}/enviarCorreo?area=${cookies.get(
              "AreaTrabajo"
            )}&folio=${data}`
          );
          console.log("llega hasta enviar correo");
          const data2 = await res2.json();
          if (data2 === true) {
            return swal({
              title: "Correo Enviado",
              icon: "success",
              button: "OK",
            }).then(() => navigate("/ordenTrabajo"));
          } else {
            return swal({
              title: "Ha Ocurrido un Problema al Enviar Correo",
              icon: "error",
              button: "OK",
            });
          }
        });
      } else {
        return swal({
          title: "Ha Ocurrido un Error al Guardar Orden de Trabajo",
          icon: "error",
          button: "ok",
        });
      }
    });
  };

  const addField = async () => {
    const idArea = cookies.get("AreaTrabajo");

    if (tituloModal === "Nivel") {
      if (datoAgregar2 === "" || selectAgregar === "") {
        return swal({
          title: "Todos los campos son obligatorios.",
          icon: "warning",
          button: "ok",
        });
      }
    } else {
      if (datoAgregar === "") {
        return swal({
          title: "Todos los campos son obligatorios.",
          icon: "warning",
          button: "ok",
        });
      }
    }

    const res = await fetch(api + "/agregarField", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tituloModal,
        datoAgregar,
        datoAgregar2,
        selectAgregar,
        idArea,
      }),
    });
    const data = await res.json();

    if (data === true) {
      return swal({
        title: "Se Agregó el Registro Correctamente",
        icon: "success",
        button: {
          text: "OK",
        },
      }).then(() => {
        getDatos();
        setShow(false);
        setShow2(false);
        setDatoAgregar("");
        setDatoAgregar2("");
      });
    } else {
      return swal({
        title: "Ocurrió un Error al Crear el Registro",
        icon: "error",
        button: {
          text: "OK",
          closeModal: true,
        },
      });
    }
  };

  useEffect(() => {
    getDatos();
  }, []);

  if (cookies.get("codigoUsuario") !== undefined) {
    return (
      <React.Fragment>
        <legend>
          <fieldset className='fieldsetOrden'>ORDEN DE TRABAJO</fieldset>
          <div className='contenedorFormulario'>
            <div className='contenedorServicioFormulario'>
              <FormLabel className='letra m-0'>Servicio</FormLabel>
              <InputGroup>
                <FormSelect
                  className='letra m-0'
                  onChange={(e) => setServicioSeleccionado(e.target.value)}
                >
                  <option>Seleccione una Opción</option>
                  {servicio.map((result) => (
                    <option value={result["Código Servicio"]}>
                      {result["Nombre Servicio"]}
                    </option>
                  ))}
                </FormSelect>
                <InputGroup.Text
                  className='btn btn-warning'
                  id='basic-addon2'
                  onClick={() => {
                    setShow(true);
                    setTituloModal("Servicio");
                  }}
                >
                  +
                </InputGroup.Text>
              </InputGroup>
            </div>

            <div className='contenedorDoble'>
              <div>
                <FormLabel className='letra m-0'>Activo</FormLabel>
                <InputGroup>
                  <FormSelect
                    className='letra m-0'
                    onChange={(e) => setActivoSeleccionado(e.target.value)}
                  >
                    <option>Seleccione una Opción</option>
                    {activo.map((result) => (
                      <option value={result["Código Activo"]}>
                        {result["Nombre Activo"]}
                      </option>
                    ))}
                  </FormSelect>
                  <InputGroup.Text
                    className='btn btn-warning'
                    id='basic-addon2'
                    onClick={() => {
                      setShow(true);
                      setTituloModal("Activo");
                    }}
                  >
                    +
                  </InputGroup.Text>
                </InputGroup>
              </div>

              <div>
                <FormLabel className='letra m-0'>Edificio</FormLabel>

                <InputGroup>
                  <FormSelect
                    className='letra m-0'
                    onChange={(e) => {
                      llenarNivel(e.target.value);
                      setEdificioSeleccionado(e.target.value);
                    }}
                  >
                    <option>Seleccione una Opción</option>
                    {edificio.map((result) => (
                      <option value={result["Código Edificio"]}>
                        {result["Nombre Edificio"]}
                      </option>
                    ))}
                  </FormSelect>
                  <InputGroup.Text
                    className='btn btn-warning'
                    id='basic-addon2'
                    onClick={() => {
                      setShow(true);
                      setTituloModal("Edificio");
                    }}
                  >
                    +
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </div>

            <div className='contenedorDoble'>
              <div>
                <FormLabel className='letra m-0'>Nivel</FormLabel>

                <InputGroup>
                  <FormSelect
                    className='letra m-0'
                    value={nivelSeleccionado}
                    onChange={(e) => setNivelSeleccionado(e.target.value)}
                  >
                    <option>Seleccione una Opción</option>
                    {nivel.map((result) => (
                      <option value={result["Código Nivel"]}>
                        {result["Nombre Nivel"]}
                      </option>
                    ))}
                  </FormSelect>
                  <InputGroup.Text
                    className='btn btn-warning'
                    id='basic-addon2'
                    onClick={() => {
                      setShow2(true);
                      setTituloModal("Nivel");
                    }}
                  >
                    +
                  </InputGroup.Text>
                </InputGroup>
              </div>

              <div>
                <FormLabel className='letra m-0'>Fecha</FormLabel>
                <FormControl
                  type='date'
                  disabled='disabled'
                  className='letra m-0'
                  value={`${fechaHoy.getFullYear()}-${mes}-${dia}`}
                ></FormControl>
              </div>
            </div>
          </div>

          <legend>
            <fieldset className='mt-3'>DATOS DE GENERALES</fieldset>

            <div className='contenedorDoble'>
              <div>
                <FormLabel className='letra m-0'>Nombre Empresa</FormLabel>
                <FormControl
                  className='letra m-0'
                  type='text'
                  value={empresaEncargada}
                  onChange={(e) => setEmpresaEncargada(e.target.value)}
                ></FormControl>
              </div>

              <div>
                <FormLabel className='letra m-0'>Encargado Empresa</FormLabel>
                <FormControl
                  className='letra m-0'
                  type='text'
                  value={encargadoEmpresa}
                  onChange={(e) => setEncargadoEmpresa(e.target.value)}
                ></FormControl>
              </div>
            </div>

            <div className='contenedorDoble'>
              <div>
                <FormLabel className='letra m-0'>Fecha Inicio</FormLabel>
                <FormControl
                  type='date'
                  className='letra m-0'
                  value={fechaInicio}
                  onChange={(e) => {
                    setFechaInicio(e.target.value);
                  }}
                ></FormControl>
              </div>

              <div>
                <FormLabel className='letra m-0'>Fecha Fin</FormLabel>
                <FormControl
                  type='date'
                  className='letra m-0'
                  value={fechaFin}
                  onChange={(e) => {
                    setFechaFin(e.target.value);
                  }}
                ></FormControl>
              </div>
            </div>
          </legend>

          <div className='mt-1' style={{ display: `${mostarFormulario}` }}>
            <div className='contenedorDoble'>
              <div>
                <FormLabel className='letra m-0'>Actividad</FormLabel>

                <InputGroup>
                  <FormSelect
                    className='letra m-0'
                    value={actividadesSeleccionada}
                    onChange={(e) => {
                      setActividadesSeleccionada(e.target.value);
                      let index = e.target.selectedIndex;
                      setTexto(e.target.options[index].text);
                    }}
                  >
                    <option value=''>Seleccione una Opción</option>
                    {actividades.map((result) => (
                      <option value={result["Código Actividad"]}>
                        {result["Nombre Actividad"]}
                      </option>
                    ))}
                  </FormSelect>
                  <InputGroup.Text
                    className='btn btn-warning'
                    id='basic-addon2'
                    onClick={() => {
                      setShow(true);
                      setTituloModal("Actividad");
                    }}
                  >
                    +
                  </InputGroup.Text>
                </InputGroup>
              </div>

              <div>
                <FormLabel className='letra m-0'>Personal Técnico</FormLabel>
                <InputGroup>
                  <FormSelect
                    className='letra m-0'
                    value={personaSeleccionada}
                    onChange={(e) => {
                      setPersonaSeleccionada(e.target.value);
                      let index = e.target.selectedIndex;
                      setTexto2(e.target.options[index].text);
                    }}
                  >
                    <option value=''>Seleccione una Opción</option>
                    {persona.map((result) => (
                      <option value={result["Código Personal"]}>
                        {result["Nombre Personal"]}
                      </option>
                    ))}
                  </FormSelect>
                  <InputGroup.Text
                    className='btn btn-warning'
                    id='basic-addon2'
                    onClick={() => {
                      setShow(true);
                      setTituloModal("Personal Técnico");
                    }}
                  >
                    +
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </div>

            <div className='contenedorDoble'>
              <div>
                <FormLabel className='letra m-0'>Hora Inicio</FormLabel>
                <FormControl
                  type='time'
                  className='letra m-0'
                  value={fechaActividad}
                  onChange={(e) => {
                    setfechaActividad(e.target.value);
                  }}
                ></FormControl>
              </div>

              <div>
                <FormLabel className='letra m-0'>Hora Fin</FormLabel>
                <FormControl
                  type='time'
                  className='letra m-0'
                  value={horaActividad}
                  onChange={(e) => {
                    sethoraActividad(e.target.value);
                  }}
                ></FormControl>
              </div>
            </div>
          </div>

          <div className='mt-1'>
            <Table
              striped
              className='letra'
              style={{ display: `${mostarAgregar}` }}
            >
              <thead>
                <tr>
                  <th>N°</th>
                  <th>Actividad</th>
                  <th>Personal Técnico</th>
                  <th>Hora Inicio</th>
                  <th>Hora Fin</th>
                </tr>
              </thead>
              <tbody id='tablaBody'></tbody>
            </Table>

            <div className='contenedorBotones'>
              <Button
                variant='danger'
                style={{ display: `${mostarFormulario}` }}
                onClick={() => {
                  setmostarFormulario("none");
                  setmostarAgregar("");
                }}
              >
                Cancelar
              </Button>
              <Button
                style={{ display: `${mostarFormulario}` }}
                variant='primary'
                onClick={() => {
                  const body = document.getElementById("tablaBody");
                  body.innerHTML += `
                  <tr>
                  <td>${contador}</td>
                  <td>${texto}</td>
                  <td>${texto2}</td>
                  <td>${fechaActividad}</td>
                  <td>${horaActividad}</td>      
                  </tr>`;

                  setmostarFormulario("none");
                  setmostarAgregar("");
                  setContador(parseInt(contador) + 1);
                  //const objetc={nombreEmpresa:texto,EncargadoEmpresa:texto2,horaInicio:fechaActividad,horaFin:horaActividad}
                  const array = [
                    actividadesSeleccionada,
                    personaSeleccionada,
                    fechaActividad,
                    horaActividad,
                  ];
                  setarrayActividades([...arrayActividades, array]);
                  console.log(arrayActividades, array);
                }}
              >
                Guardar
              </Button>
            </div>
          </div>
          <Button
            variant='primary'
            style={{ display: `${mostarAgregar}` }}
            onClick={() => {
              setmostarFormulario("");
              setmostarAgregar("none");
            }}
          >
            Agregar
          </Button>

          <div>
            <Button
              variant='success'
              className='col-md-12 mt-3'
              onClick={crearOrdenTrabajo}
            >
              Crear Orden Programada
            </Button>
          </div>

          <div>
            <Button
              variant='warning'
              className='col-md-12 mt-3'
              onClick={() => {
                navigate("/ordenTrabajo");
              }}
            >
              Regresar
            </Button>
          </div>
        </legend>

        <div
          className='modal show'
          style={{ display: "block", position: "initial" }}
        >
          <Modal show={show}>
            <Modal.Header>
              <Modal.Title>Agregar {tituloModal}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form.Label>Nombre {tituloModal}</Form.Label>
              <Form.Control
                type='text'
                value={datoAgregar}
                onChange={(e) => {
                  setDatoAgregar(e.target.value);
                }}
              ></Form.Control>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant='secondary'
                onClick={() => {
                  setShow(false);
                }}
              >
                Cerrar
              </Button>
              <Button
                variant='primary'
                onClick={() => {
                  addField();
                }}
              >
                Guardar Cambios
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <div
          className='modal show'
          style={{ display: "block", position: "initial" }}
        >
          <Modal show={show2}>
            <Modal.Header>
              <Modal.Title>Agregar {tituloModal}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form.Label>Nombre {tituloModal}</Form.Label>

              <FormLabel>Edificio</FormLabel>
              <FormSelect
                className='letra m-0'
                value={selectAgregar}
                onChange={(e) => {
                  llenarNivel(e.target.value);
                  setselectAgregar(e.target.value);
                }}
              >
                <option value=''>Seleccione una Opción</option>
                {edificio.map((result) => (
                  <option value={result["Código Edificio"]}>
                    {result["Nombre Edificio"]}
                  </option>
                ))}
              </FormSelect>
              <FormLabel>Nivel</FormLabel>
              <FormControl
                type='text'
                value={datoAgregar2}
                onChange={(e) => {
                  setDatoAgregar2(e.target.value);
                }}
              ></FormControl>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant='secondary'
                onClick={() => {
                  setShow2(false);
                }}
              >
                Cerrar
              </Button>
              <Button
                variant='primary'
                onClick={() => {
                  addField();
                }}
              >
                Guardar Cambios
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </React.Fragment>
    );
  } else {
    return <Login></Login>;
  }
}

export default AgregarOrdenProgramada;
