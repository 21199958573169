import React,{useState,useEffect} from 'react'
import { useNavigate,NavLink } from "react-router-dom";
import Cookies from "universal-cookie/es6";



function Sidebard() {
  const cookies = new Cookies();
  let navigate = useNavigate();
  
  const rol=cookies.get("tipoFirma");
  const [mostrarBienes,setmostrarBienes]=useState("")

useEffect(() => {
  
  if(rol==="ViewerBienes"){
    setmostrarBienes("none")
  }else{
    setmostrarBienes("") 
  }

}, [])

  

  const cerrarSesion=()=>{
    cookies.remove("codigoUsuario");
    cookies.remove("nombre");
    cookies.remove("apellido");
    cookies.remove("correo");
    cookies.remove("AreaTrabajo");
    cookies.remove("tipoFirma");
    return navigate("/")
    
  }

  return (
    <div className="d-flex flex-column flex-shrink-0 p-3 bg-light justify-content-between" style={{width: '17vw',minHeight:"100vh"}}>
    
    <div>
    <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
      <svg className="bi pe-none me-2" width={40} height={32}><use xlinkHref="#bootstrap" /></svg>
      <span className="fs-4">Operadora CC</span>
    </a>
    <hr />
    <ul className="nav nav-pills flex-column mb-auto">
      <li className="nav-item" style={{display:`${mostrarBienes}`}}>
        <NavLink to="/dashboard" className="nav-link"  aria-current="page">
          Dashboard
        </NavLink>
      </li>
      <li >
        <NavLink to="/ordenTrabajo" className="nav-link" style={{display:`${mostrarBienes}`}}>
        Orden Programada
        </NavLink>
        <NavLink to="/bienesAfectos" className="nav-link" >
        Bienes Afectos
        </NavLink>
      </li>
    </ul>
    </div>
    

    <div className='nav nav-pills flex-column mb-auto '>
      <div className='nav-item'>
      <label  className="nav-link" style={{marginTop:"60vh"}} onClick={cerrarSesion} >
        Cerrar Sesión
        </label>

      </div>
    </div>
  </div>
  )
}

export default Sidebard