

class crearcuenta{
    constructor(nombre,apellido,correoElectronico,contrasena,areaTrabajo){
         this.nombre=nombre;
         this.apellido=apellido;
         this.correoElectronico=correoElectronico;
         this.contrasena=contrasena;
         this.areaTrabajo=areaTrabajo;
    }
} 

export default crearcuenta