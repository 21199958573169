import React, { useEffect, useState } from "react";
import imagen from "../assets/logoOperadora.png";
import "../css/consultaFormato2.css";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useWindowSize } from "react-use";
import Cookies from "universal-cookie/es6";

const api = "http://181.115.47.108:8001";

function ConsultaOrden({ ...props }) {
  const cookies = new Cookies();
  const { folio } = useParams();
  const navigate = useNavigate();

  const { width } = useWindowSize();
  const [id, setId] = useState("");
  const [servicio, setServicio] = useState([]);
  const [edificio, setEdificio] = useState("");
  const [nivel, setNivel] = useState("");
  const [activo, setActivo] = useState("");
  const [fecha, setFecha] = useState("");
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [encargadoOcc, setencargadoOcc] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [encargado, setEncargado] = useState("");
  const [tabla, setTabla] = useState([]);
  const [imagen2, setImagen2] = useState(null);
  const [imagen3, setImagen3] = useState(null);
  const [imagen4, setImagen4] = useState(null);

  const getOrden = async () => {
    const res = await fetch(`${api}/consultarOrdenT?folio=${folio}`);
    const data = await res.json();
    setId(data[0].nombreServicio);
    setServicio(data[0].nombreServicio);
    setEdificio(data[0].nombreEdificio);
    setNivel(data[0].nivel);
    setActivo(data[0].nombreActivo);
    setFecha(data[0].fecha.slice(0, 10));
    setFechaInicio(data[0].fechaInicio.slice(0, 10));
    setFechaFin(data[0].fechaFin.slice(0, 10));
    setencargadoOcc(data[0].nombre + " " + data[0].apellido);
    setEmpresa(data[0].nombreEmpresa);
    setEncargado(data[0].nombreEncargado);

    const res2 = await fetch(`${api}/consultarActividades?folio=${folio}`);
    const data2 = await res2.json();
    setTabla(data2);
    console.log(data2);

    const res3 = await fetch(`${api}/consultarFirma?folio=${folio}`);
    const data3 = await res3.json();
    console.log(data3);
    let array = [];
    data3.map((data) => {
      array.push(data.firma);
      return "";
    });

    setImagen2(array[0]);
    setImagen3(array[1]);
    setImagen4(array[2]);
  };

  useEffect(() => {
    getOrden();
  }, [folio]);

  return (
    <React.Fragment>
      <div id='content'>
        <main>
          <div className='contenedorImagenF2'>
            <img alt='Logo' src={imagen} className='imagenLogoF2' />
          </div>
          <div className='contenedorTextoEncabezado'>
            <div className='contenedorEncabezado1'>
              <label className='labelEncabezado'>
                <span className='spanEncabezado'>
                  Desarolladora y Operadora de Infraestructura de Honduras
                </span>
              </label>
            </div>
            <div className='contenedorEncabezado2'>
              <label className='labelEncabezado'>
                <span className='spanEncabezado'>Título del Documento:</span>
                Orden de Trabajo
              </label>
              <label className='labelEncabezado2'>
                {" "}
                <span className='spanEncabezado'>Fecha:</span>01/07/2021
              </label>
            </div>

            <div className='contenedorEncabezado3'>
              <label className='labelEncabezado'>
                <span className='spanEncabezado'>Código: </span>Orden de Trabajo
              </label>
              <label className='labelEncabezado2'>
                <span className='spanEncabezado'>Estatus:</span> Aprobado
              </label>
              <label className='labelEncabezado2'>
                <span className='spanEncabezado'>REV. 1</span>
              </label>
            </div>

            <div className='contenedorEncabezado4'>
              <label className='labelEncabezado'>
                <span className='spanEncabezado'>
                  Emitido por el Departamento:{" "}
                </span>
                MAO
              </label>
              <label className='labelEncabezado2'>
                {" "}
                <span className='spanEncabezado'>Página 1 de 1</span>
              </label>
            </div>
          </div>
        </main>

        <body>
          <div className='tituloBody'>
            <h1 className='h1consultar'>Orden de Trabajo</h1>
          </div>

          <div className='datos'>
            <div className='datos1'>
              <label className='datoslabel1'>SERVICIO</label>
              <label className='datoslabel2'>{servicio}</label>
              <label className='datoslabel1'>FOLIO</label>
              <label className='datoslabel2'>
                {cookies.get("correlativo") + "-" + folio}
              </label>
            </div>
            <div className='datos2'>
              <label className='datoslabel1'>EDIFICIO</label>
              <label className='datoslabel2'>{edificio}</label>
              <label className='datoslabel1'>NIVEL</label>
              <label className='datoslabel2'>{nivel}</label>
            </div>
            <div className='datos2'>
              <label className='datoslabel1'>ACTIVO</label>
              <label className='datoslabel2'>{activo}</label>
              <label className='datoslabel1'>FECHA</label>
              <label className='datoslabel2'>{fecha}</label>
            </div>

            <div className='tituloBody'>
              <h4 className='h4Consultar'>DATOS GENERALES</h4>
            </div>
          </div>

          <div className='datosGeneralesF2'>
            <div className='datosGenerales1'>
              <label className='datoslabel3'>ENCARGADO OCC</label>
              <label className='datoslabel2'>{encargadoOcc}</label>
            </div>
            <div className='datosGenerales2'>
              <label className='datoslabel3'>NOMBRE DE EMPRESA</label>
              <label className='datoslabel2'>{empresa}</label>
            </div>

            <div className='datosGenerales2'>
              <label className='datoslabel3'>ENCARGADO DE EMPRESA</label>
              <label className='datoslabel2'>{encargado}</label>
            </div>

            <div className='datosGenerales3'>
              <label className='datoslabel3'>FECHA INICIO</label>
              <label className='datoslabel2'>{fechaInicio}</label>
              <label className='datoslabel4'>FECHA FIN</label>
              <label className='datoslabel2'>{fechaFin}</label>
            </div>
          </div>

          <div className='contenedorTabla'>
            <table className='tabla2'>
              <thead>
                <tr>
                  <td>ACTIVIDAD</td>
                  <td>PERSONAL TÉCNICO</td>
                  <td>HORA INICIO</td>
                  <td>HORA FINAL</td>
                </tr>
              </thead>
              <tbody>
                {tabla.map((result) => (
                  <tr>
                    <td>{result.nombreActividad}</td>
                    <td>{result.nombre}</td>
                    <td>{result.horaInicio}</td>
                    <td>{result.horaFin}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className='contenedorFirmas'>
            <div className='contenedorFirma1'>
              {imagen2 ? (
                <img
                  src={`data:image/png;base64,${imagen2}`}
                  alt='Firma1'
                  className='imagenFirma'
                />
              ) : null}
              <label className={imagen2 ? "labelFirma" : "mTop"}>
                Firma Gerente o Coordinador de Servicio
              </label>
            </div>

            <div className='contenedorFirma1'>
              {imagen3 ? (
                <img
                  src={`data:image/png;base64,${imagen3}`}
                  alt='Firma2'
                  className='imagenFirma'
                />
              ) : null}

              <label className={imagen3 ? "labelFirma" : "mTop"}>
                Firma Jefe o Coordinador MAO{" "}
              </label>
            </div>
            <div className='contenedorFirma1'>
              {imagen4 ? (
                <img
                  src={`data:image/png;base64,${imagen4}`}
                  alt='Firma3'
                  className='imagenFirma'
                />
              ) : null}
              <label className={imagen4 ? "labelFirma" : "mTop"}>
                Firma Coordinador de Unidad Funcional
              </label>
            </div>
          </div>
        </body>

        <footer>
          <div>
            Nota: En fines de Semana, feriados y horario inhábil, no requiere
            firma por parte de la MAO y Coordinador de unidad funcional.
          </div>

          <div className='contenedor1Footer'>
            <label>Documento Controlado Por Mesa de Atención Operativa</label>
            <label>
              Para uso exclusivo del personal de operadora cetro cívico
              Gubernamental
            </label>
          </div>
          <hr />

          <div>
            La información contenida en este documento es propiedad de DOIH.
            Esta no debe ser copiada, revelada, distribuida o proporcionada por
            ningún medio a terceros sin el previo consentimiento de DOIH.
          </div>
        </footer>
      </div>

      <div className='contenedorBotonRegresar'>
        <Button
          variant='danger'
          onClick={() => {
            const input = document.getElementById("content");
            html2canvas(input).then((canvas) => {
              let imgWidth;
              let imgHeight;
              if (width < 768) {
                imgWidth = 180;
                imgHeight = (canvas.height * imgWidth) / canvas.width;
                const imgData = canvas.toDataURL("img/png");
                const pdf = new jsPDF("p", "mm", "a4");
                pdf.addImage(imgData, "PNG", 13, 10, imgWidth, imgHeight);
                // pdf.output('dataurlnewwindow');
                pdf.save(folio + ".pdf");
              } else {
                imgWidth = 280;
                imgHeight = (canvas.height * imgWidth) / canvas.width;
                const imgData = canvas.toDataURL("img/png");
                const pdf = new jsPDF("l", "mm", "a4");
                pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
                // pdf.output('dataurlnewwindow');
                pdf.save(
                  cookies.get("correlativo") +
                    "-" +
                    folio +
                    "_" +
                    fecha +
                    ".pdf"
                );
              }
            });
          }}
        >
          Guardar PDF
        </Button>
        <Button variant='warning' onClick={() => navigate("/ordenTrabajo")}>
          Regresar
        </Button>
      </div>
    </React.Fragment>
  );
}

export default ConsultaOrden;
